import {
    TableChartRow,
    TableChartRowChangeIndicator,
    TableChartRowChangeValue,
    TableChartRowKey,
    TableChartRowValue,
    TableChartWrapper,
    TableRowLeftSpacer
} from './ChartWrappers.css';
import SVGInline from 'react-inlinesvg';
import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';
import { PlatformAnalyticsData } from '../../../redux/slices/analyticsSlice';
import { analyticsChangeIconSwitcher, calculatePercentageAndIndicator, createPercentChangeLabel } from './ChartUtils';

const AnalyticsTableChart: FC<{ tableData: PlatformAnalyticsData; comparedTimeframeLabel: string; totalDevices: number }> = ({
    tableData,
    comparedTimeframeLabel,
    totalDevices
}) => {
    return (
        <TableChartWrapper>
            {tableData.map((data, index) => {
                const { percentChange, changeIndicator } = calculatePercentageAndIndicator(
                    totalDevices,
                    data.value,
                    data.comparedValue || 0
                );

                return (
                    <TableChartRow key={data.id}>
                        <Tooltip title={data.label || data.id} placement={tooltipPositions.TOP}>
                            <TableChartRowKey>{data.label || data.id}</TableChartRowKey>
                        </Tooltip>
                        <TableRowLeftSpacer />
                        <TableChartRowValue>{data.value}</TableChartRowValue>

                        <TableChartRowChangeValue>{createPercentChangeLabel(percentChange)}</TableChartRowChangeValue>
                        {renderTooltip(
                            <TableChartRowChangeIndicator>
                                <SVGInline src={analyticsChangeIconSwitcher(changeIndicator)} />
                            </TableChartRowChangeIndicator>,
                            tooltipTypes.TEXT,
                            `${data.comparedValue || 0} ${comparedTimeframeLabel}`,
                            tooltipPositions.TOP,
                            true
                        )}
                    </TableChartRow>
                );
            })}
        </TableChartWrapper>
    );
};

export default AnalyticsTableChart;
