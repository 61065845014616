import React, { FC, useEffect, useState } from 'react';
import { ActiveItemState } from '../../../redux/slices/activeItemSlice';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import {
    audiencesState,
    createAudience,
    deleteAudience,
    fetchAudiences,
    unsetAudiences,
    updateAudience
} from '../../../redux/slices/audienceSlice';
import { fetchTargetGroups, targetGroupsState } from '../../../redux/slices/targetGroupsSlice';
import { Audience, AudienceFilterHeaders, audienceFilterKeys, predefinedAudienceValues } from '../../../types/Audience';
import { DIALOG_NAMES, dialogAlert, dialogConfirm } from '../../../utils/fnDialogs';
import { setUserPermissions } from '../../../redux/slices/permissionsSlice';
import {
    ActionIconHolder,
    ItemActions,
    ItemAddIcon,
    ItemCard,
    ItemCardAdd,
    ItemIcon,
    ItemName,
    ItemType,
    ListAddNew,
    ListItemContainer
} from '../../TargetGroups/UXElements/Audiences/Audiences.css';
import { DialogDropdownSingle, DialogTextField } from '../../common/Dialog/GenericDialog';
import _ from 'lodash';
import SVGInline from 'react-inlinesvg';
import icons from '../../../assets/images/icons';
import { renderTooltipWithKey } from '../../common/Tooltips/Tooltips';
import { Loader } from '../../common/Loader/Loader';
import BackendErrorDialog from '../../common/Dialog/BackendErrorDialog';
import { generateDateStringForTables } from '../../../utils/fnDate';
import { API_ERROR_CODES, EMPTY_WORD_STRING } from '../../../utils/Globals';
import { TableRow, Tooltip } from '@material-ui/core';
import NewAudience from '../../TargetGroups/Dialogs/NewAudience';
import PageActions from '../../common/PageActions/PageActions';
import { audienceVersionTypeSymbols } from '../../TargetGroups/UXElements/Audiences/Audiences';
import { MoreInfoTypes } from '../../common/Dialog/MoreInfoDialog';
import {
    AudiencesGridView,
    AudienceTargetGroupName,
    AudienceTitle,
    AudienceTitleInput,
    LastModified,
    ListContainer,
    TargetGroupDropdownWrapper
} from './AudienceLibrary.css';
import useBlockNavigation from '../../../hooks/useBlockNavigation';
import {
    ApplicationWrapper,
    MainContentWrapper,
    ObjectListHeader,
    ObjectListItem,
    ObjectListWrapper,
    ObjectNameLabel,
    PageActionButton,
    PageActionsWrapper,
    PlacedInPublishedWarningMessageWrapper,
    TruncatedText
} from '../../../style/styled-components/reusable.css';
import useScreenSize from '../../../hooks/useScreenSize';
import { SearchBar } from '../../common/SearchBar/SearchBar';
import { TargetingGuide } from '../../TargetGroups/UXElements/Audiences/Dialogs/TargetingGuide';
import Sidebar from '../../common/Sidebar/Sidebar';
import ScreenTitle from '../../common/DashboardTitle/ScreenTitle';
import { renderLockedError, renderLockedWarningAlert, renderLockIcon } from '../../../utils/fnLockingSystem';
import ToggleViewSwitch from '../../common/Switch/ToggleViewSwitch';
import { clientTokenGenerator } from '../../../utils/fnData';
import configServiceAPI from '../../../utils/api/configServiceAPI';
import { buildPathWithProjectId, PageRoutes } from '../../../types/RouteTypes';
import { RemoveModuleWrapper } from '../../Modules/Modules.css';
import { audiencesIcons } from '../../../assets/images/icons/audiencesIcons';
import useLockSystem, { LockableObjectTypes } from '../../../hooks/useLockSystem';
import { Page } from '../../../types/Page';
import { Menu } from '../../../types/Menu';
import { Setting } from '../../../types/Setting';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ACCEPTED_SORT_FIELDS,
    AcceptedSortField,
    calculateOrderByFromSortConfig,
    DEFAULT_SORT_CONFIG_FOR_AUDIENCES,
    ISortConfig
} from '../../../utils/fnSort';
import { ObjectActions } from '../../common/Actions/Actions';
import GenericTable, { HeaderTableCell, SortableHeaderTableCell, tableActions } from '../../common/Table/Table';
import { getSearchParam } from '../../../utils/fnUrl';
import { AudiencesTableSizes } from '../../../types/TableSizes';
import { TableRowWrapper, WidthTableCell } from '../../common/Table/Table.css';
import Labels from '../../common/Labels/Labels';
import { ScrollableTableRowWrapper } from '../../Menus/Menus';
import { CIRCLE_SLUGS, ONBOARDING_CIRCLE_SLUGS } from '../../common/HelpIcon/HelpIcon';
import { SearchBarContainer } from '../../common/SearchBar/SearchBar.css';
import { ObjectTypes } from '../../../types/Object';
import useTranslation from '../../../hooks/useTranslation';
import { FeatureFlag } from '../../../types/FeatureFlags';
import { AddAudienceToGroupObjectsDialog } from '../../TargetGroups/Dialogs/AddAudienceToGroupObjectsDialog';

export const AUDIENCE_LIBRARY_ADD_BUTTON_ID = 'AUDIENCE_LIBRARY_ADD_BUTTON_ID';

const AudienceLibrary: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location: any = useLocation();
    const { isDesktop, isMobile, isLargeDesktop } = useScreenSize();
    const { translate } = useTranslation();

    const { activeProjectId, activeTenantId }: ActiveItemState = useAppSelector((state: any) => state.activeItem);
    const { audiences: storeAudiences, error, loading }: audiencesState = useAppSelector((state) => state.audiences);
    const { targetGroups }: targetGroupsState = useAppSelector((state) => state.targetGroups);

    const [view, setView] = useState<'LIST' | 'GRID'>('GRID');
    const [creatingAudience, setCreatingAudience] = useState(false);
    const [showUnsaved, setShowUnsaved] = useState<boolean>(false);
    const [openHeaderValuesDialog, setOpenHeaderValuesDialog] = useState(false);
    const [audienceGuide, setAudienceGuide] = useState<Audience | undefined>(undefined);

    const [showAddAudienceToGroupObjectDialog, setShowAddAudienceToGroupObjectDialog] = useState<boolean>(false);

    const [objectsWithoutCurrentAudience, setObjectsWithoutCurrentAudience] = useState<{
        pages?: Page[];
        menus?: Menu[];
        settings?: Setting[];
        featureFlags?: FeatureFlag[];
    }>({});

    //  Hold the Audience object which is being worked on
    const [audienceToEdit, setAudienceToEdit] = useState<Audience | undefined>(undefined);
    // Tracks whether the top values can be edited or not
    const [isEditing, setIsEditing] = useState(false);
    const { lock, unlock, unlockOnClose, isObjectLocked, objectIsLockedBy } = useLockSystem(LockableObjectTypes.AUDIENCES);

    const [targetGroupOptions, setTargetGroupOptions] = useState<{ label: string; value: string }[]>([]);
    const [redirectAudienceId, setRedirectAudienceId] = useState<string>('');

    // PAGINATION, SEARCH AND FILTERING/SORTING RELATED FIELDS
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [sortConfig, setSortConfig] = useState<ISortConfig>(DEFAULT_SORT_CONFIG_FOR_AUDIENCES);
    const [showSortArrows, setShowSortArrows] = useState<boolean>(false);
    const [activeSortingKey, setActiveSortingKey] = useState<AcceptedSortField>(ACCEPTED_SORT_FIELDS.targetGroupId);

    const orderBy = `${sortConfig.field}[${sortConfig.direction}]`;
    // -------------------

    // Blocking navigation when there are unsaved changes
    const unsavedDependencyArray = [creatingAudience, audienceToEdit];
    useBlockNavigation(showUnsaved, () => renderAlertUnsavedChanges(), unsavedDependencyArray);

    const loadProjectToken = async () => {
        let token = '';
        const { response } = await configServiceAPI.getToken(activeProjectId || '');
        if (response) {
            token = (response as any).token;
        }
        return token;
    };

    const loadAudiences = async (orderBy?: string, searchTerm?: string) => {
        if (storeAudiences.length && getSearchParam('id')) {
            navigate(buildPathWithProjectId(activeProjectId, PageRoutes.AUDIENCES));
        }
        return await dispatch(fetchAudiences({ addPermissions: true, projectId: activeProjectId, orderBy, searchTerm })).unwrap();
    };

    const modifyAudience = async (newAudience: Audience, objectIdsToAddAudienceTo?: string[]) => {
        await dispatch(updateAudience({ audience: newAudience, shouldUnlockAfterSave: true, objectIdsToAddAudienceTo })).unwrap();
        loadAudiences(orderBy, searchTerm);
    };

    const loadTargetGroups = async () => {
        await dispatch(fetchTargetGroups({ projectId: activeProjectId }));
    };

    const saveAudience = async (newAudience: Audience, objectIdsToAddAudienceTo?: string[]) => {
        const response = await dispatch(createAudience({ audience: newAudience, objectIdsToAddAudienceTo })).unwrap();
        if (!response.id) {
            const values = {
                title: 'Oops.. An error occured.',
                text: response.error
            };
            return dialogAlert('', false, values, undefined, true);
        }
        loadAudiences(calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG_FOR_AUDIENCES));
        setSearchTerm(undefined);
        setSortConfig(DEFAULT_SORT_CONFIG_FOR_AUDIENCES);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.targetGroupId);
    };

    const removeAudience = async (id: string) => {
        await dispatch(deleteAudience(id)).unwrap();
        loadAudiences(orderBy, searchTerm);
    };

    const handleOnSearch = (searchTerm: string) => {
        loadAudiences(orderBy, searchTerm);
    };

    const purgeCdnForAudience = async (audience: any) => {
        const headers = Object.keys(audience).reduce((prev, key) => {
            const newHeaders = { ...prev };
            if (audienceFilterKeys.includes(key)) {
                _.set(newHeaders, _.get(AudienceFilterHeaders, key), _.get(audience, key));
            }
            return newHeaders;
        }, {});
        const apiKey = await loadProjectToken();
        const token = clientTokenGenerator(apiKey, headers, AudienceFilterHeaders);
        const response = await configServiceAPI.purgeCdn(token, audience._id);
        if (!response.error) {
            dialogAlert(DIALOG_NAMES.AUDIENCE_PURGE_SUCCESS);
        } else {
            if (response.error.code === API_ERROR_CODES.LOCKED_ERROR) {
                return renderLockedWarningAlert(response.error.data.name);
            }
            dialogAlert(DIALOG_NAMES.AUDIENCE_PURGE_ERROR);
        }
    };

    useEffect(() => {
        if (!activeProjectId) return;
        setSearchTerm('');
        setSortConfig(DEFAULT_SORT_CONFIG_FOR_AUDIENCES);
        setActiveSortingKey(ACCEPTED_SORT_FIELDS.targetGroupId);
        if (storeAudiences.length) {
            dispatch(unsetAudiences());
        }
        loadAudiences(calculateOrderByFromSortConfig(DEFAULT_SORT_CONFIG_FOR_AUDIENCES)).then((response: any) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
            loadTargetGroups();
        });

        // Used to open the editor in a new tab
        const redirectAudienceId = getSearchParam('id');

        if (location?.state?.audienceId || redirectAudienceId) {
            setRedirectAudienceId(location?.state?.audienceId || redirectAudienceId);
            window.history.replaceState({}, '');
        }
    }, [activeProjectId]);

    useEffect(() => {
        if (!activeProjectId || !storeAudiences.length || isEditing) return;

        if (redirectAudienceId) {
            const audience = storeAudiences.find((audience) => audience._id === redirectAudienceId);
            setIsEditing(true);
            expandAudience(audience);
            setCreatingAudience(false);
        }
    }, [storeAudiences]);

    useEffect(() => {
        setShowUnsaved(checkUnsaved());
    }, [...unsavedDependencyArray]);

    useEffect(() => {
        setTargetGroupOptions(targetGroups?.map((tg) => ({ label: tg.name, value: tg._id })) || []);
    }, [targetGroups]);

    const handleAddClick = () => {
        if (audienceToEdit) {
            unlock(audienceToEdit._id);
        }
        setAudienceToEdit({
            _id: '',
            name: '',
            targetGroupId: '',
            projectId: activeProjectId || '',
            tenantId: activeTenantId || '',
            deviceType: predefinedAudienceValues.deviceType[0]
        });
        setCreatingAudience(true);
    };

    const expandAudience = (audience: any) => {
        if (audience?._id === audienceToEdit?._id) {
            unlock(audienceToEdit?._id || '');
            setIsEditing(false);
            setAudienceToEdit(undefined);
            return;
        }
        if (audience && isObjectLocked(audience)) {
            renderLockedWarningAlert(objectIsLockedBy(audience));
        } else {
            lock(audience._id || '');
            unlockOnClose(audience._id || '');
        }

        setAudienceToEdit(_.cloneDeep(audience));
    };

    const handleDeleteClick = (id: string) => {
        const values = {
            title: 'Remove Audience',
            text: ''
        };

        dialogConfirm(
            '',
            () => {
                removeAudience(id);
            },
            values,
            <RemoveModuleWrapper>
                <p>
                    <strong>Are you sure you want to remove this Audience?</strong>
                    <br />
                    By Pressing “Remove” you still will be able to create new one!
                    <PlacedInPublishedWarningMessageWrapper>
                        If the Audience is placed in a published object, it will also be removed from it!
                    </PlacedInPublishedWarningMessageWrapper>
                </p>
            </RemoveModuleWrapper>,
            {
                noButton: {
                    label: 'Cancel'
                },
                confirmButton: {
                    label: 'Remove'
                }
            },
            { warningIcon: true },
            undefined,
            true
        );
    };

    const splitAudienceKeys = (s: string) => {
        return (s.charAt(0).toUpperCase() + s.slice(1)).match(/[A-Z][a-z]+/g)?.join(' ');
    };

    const calculateAudienceValues = (audienceValue: any) => {
        if (typeof audienceValue !== 'object') return audienceValue;
        if (Array.isArray(audienceValue) && audienceValue.length) {
            return audienceValue.slice(0, 3).join(', ') + (audienceValue.length > 3 ? '...' : '');
        }
        if (['type', 'firstVersion', 'secondVersion'].some((r) => Object.keys(audienceValue).indexOf(r) >= 0)) {
            const type = audienceValue['type'];
            if (Object.keys(audienceValue).length > 2) {
                return `${audienceValue['firstVersion']} ${audienceVersionTypeSymbols[type]} ${audienceValue?.secondVersion}`;
            }
            return ` ${audienceVersionTypeSymbols[type]} ${audienceValue['firstVersion']} `;
        }
        return (Object.keys(audienceValue) as Array<keyof typeof audienceValue>).map((k, i) => {
            if (!audienceValue) return;
            return <span key={`${i}_value`}>{`${audienceValue[k]} `}</span>;
        });
    };

    const checkUnsaved = () => {
        if (creatingAudience) return true;
        if (!audienceToEdit) return false;

        const oldAud = storeAudiences.find((audience) => audience._id === audienceToEdit?._id);
        return !_.isEqual(oldAud, audienceToEdit);
    };

    const renderAlertUnsavedChanges = () => {
        dialogConfirm(
            DIALOG_NAMES.UNSAVED_CHANGES,
            () => {
                handleCheckBeforeSave();
            },
            null,
            null,
            {
                noButton: { label: 'Discard Changes' },
                confirmButton: { label: 'Save' }
            },
            { warningIcon: true },
            () => {
                handleCancelClick();
            },
            true
        );
    };

    const handleCheckBeforeSave = () => {
        if (!audienceToEdit) return;
        const targetGroup = targetGroups.find((tg) => tg._id === audienceToEdit.targetGroupId);
        // If there's no targetGroup, means we are unassign an audience from the group
        if (!targetGroup) {
            handleSaveClick();
            return;
        }
        const { pages, menus, settings, featureFlags } = targetGroup;
        const pagesWithoutCurrentAudience = creatingAudience
            ? (pages as Page[])
            : (pages as Page[])?.filter((page) => !page.conditionIds?.includes(audienceToEdit._id));
        const menusWithoutCurrentAudience = creatingAudience
            ? (menus as Menu[])
            : (menus as Menu[])?.filter((menu) => !menu.conditionIds?.includes(audienceToEdit._id));
        const settingsWithoutCurrentAudience = creatingAudience
            ? (settings as Setting[])
            : (settings as Setting[])?.filter((setting) => !setting.conditionIds?.includes(audienceToEdit._id));
        const featureFlagsWithoutCurrentAudience = creatingAudience
            ? (featureFlags as FeatureFlag[])
            : (featureFlags as FeatureFlag[])?.filter((flag) => !flag.conditionIds?.includes(audienceToEdit._id));

        // If everything is alright, proceed with saving and return
        if (
            !pagesWithoutCurrentAudience.length &&
            !menusWithoutCurrentAudience.length &&
            !settingsWithoutCurrentAudience.length &&
            !featureFlagsWithoutCurrentAudience.length
        ) {
            handleSaveClick();
            return;
        }

        setObjectsWithoutCurrentAudience({
            pages: pagesWithoutCurrentAudience,
            menus: menusWithoutCurrentAudience,
            settings: settingsWithoutCurrentAudience,
            featureFlags: featureFlagsWithoutCurrentAudience
        });
        setShowAddAudienceToGroupObjectDialog(true);
    };

    const handleSaveClick = (objectIdsToAddAudienceTo?: string[]) => {
        if (!audienceToEdit) return;
        const audToSave = { ...audienceToEdit };
        if (creatingAudience) {
            saveAudience(audToSave, objectIdsToAddAudienceTo);
        } else {
            modifyAudience(audToSave, objectIdsToAddAudienceTo);
        }
        handleCancelClick();
    };

    const handleCancelClick = () => {
        if (!creatingAudience && audienceToEdit) {
            unlock(audienceToEdit._id);
        }
        setCreatingAudience(false);
        setAudienceToEdit(undefined);
        setIsEditing(false);
        setRedirectAudienceId('');
    };

    const renderCreatingAudience = () => {
        if (!audienceToEdit) return;
        return (
            <>
                <TableRow style={{ height: '16px' }} />
                <ScrollableTableRowWrapper shouldScroll={creatingAudience} key={audienceToEdit._id}>
                    <WidthTableCell {...AudiencesTableSizes.name}>
                        <AudienceTitleInput>
                            <DialogTextField
                                label={'Audience Name'}
                                value={audienceToEdit?.name}
                                onChange={(evt: any) => {
                                    setAudienceToEdit({ ...audienceToEdit, name: evt.target.value });
                                }}
                                placeholder={'Add Audience Title'}
                            />
                        </AudienceTitleInput>
                    </WidthTableCell>
                    <WidthTableCell colSpan={4} {...AudiencesTableSizes.select_group}>
                        <TargetGroupDropdownWrapper>
                            <DialogDropdownSingle
                                options={targetGroupOptions}
                                placeholder={'Group'}
                                onChange={(evt: any) => {
                                    if (audienceToEdit) {
                                        const newAud = { ...audienceToEdit };
                                        newAud.targetGroupId = evt?.value || '';
                                        setAudienceToEdit(newAud);
                                    }
                                }}
                                value={targetGroupOptions.find((tgo) => tgo.value === audienceToEdit?.targetGroupId)}
                                clearable
                            />
                        </TargetGroupDropdownWrapper>
                    </WidthTableCell>
                </ScrollableTableRowWrapper>

                {/* SPACE BETWEEN ROWS */}
                <TableRow style={{ height: '8px' }} />
                <TableRowWrapper>
                    <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                        {view === 'GRID' ? renderAudienceGridView(audienceToEdit) : renderAudienceListView(audienceToEdit)}
                    </WidthTableCell>
                </TableRowWrapper>
            </>
        );
    };

    const renderAudienceListView = (audience?: Audience) => {
        if (!audience) return;
        const hasBothMandatoryKeys: boolean = audience.hasOwnProperty('deviceClass') && audience.hasOwnProperty('deviceType');
        const isLocked = isObjectLocked(audience);
        const lockedBy = objectIsLockedBy(audience);
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key)) {
                const showDeleteIcon: boolean = !['deviceClass', 'deviceType'].includes(key) || hasBothMandatoryKeys;
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];
                items.push(
                    <ListContainer key={`${index}_listview_item`}>
                        <ListItemContainer>
                            <ItemName key={key}>
                                <ItemIcon>
                                    <SVGInline
                                        src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon}
                                    />
                                </ItemIcon>
                                {_.truncate(itemValue, { length: 25 })}
                            </ItemName>
                            <ItemType>{splitAudienceKeys(key)}</ItemType>
                        </ListItemContainer>
                        <ObjectActions
                            actions={showDeleteIcon ? [tableActions.EDIT, tableActions.REMOVE] : [tableActions.EDIT]}
                            onEdit={() => {
                                if (isLocked) return renderLockedWarningAlert(lockedBy);
                                setOpenHeaderValuesDialog(true);
                            }}
                            onRemove={() => {
                                if (!showDeleteIcon) return;
                                if (isLocked) return renderLockedWarningAlert(lockedBy);
                                if (audienceToEdit) {
                                    const newAud = { ...audienceToEdit };
                                    delete newAud[key];
                                    setAudienceToEdit(newAud);
                                }
                            }}
                            tooltipTexts={{
                                edit: 'target_groups_audiences_icon_value_edit',
                                delete: 'target_groups_audiences_icon_value_delete'
                            }}
                        />
                    </ListContainer>
                );
            }
        });
        items.push(
            <>
                <ListContainer
                    onClick={() => {
                        if (isLocked) return renderLockedWarningAlert(lockedBy);
                        setOpenHeaderValuesDialog(true);
                    }}
                >
                    <ListAddNew>
                        <ItemName>
                            Add Values
                            {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_audiences_icon_value_add')}
                        </ItemName>
                    </ListAddNew>
                </ListContainer>
            </>
        );

        return <div>{items}</div>;
    };

    const renderAudienceGridView = (audience?: Audience) => {
        if (!audience) return;
        const hasBothMandatoryKeys: boolean = audience.hasOwnProperty('deviceClass') && audience.hasOwnProperty('deviceType');
        const isLocked = isObjectLocked(audience);
        const lockedBy = objectIsLockedBy(audience);
        const items: any = [];
        (Object.keys(audience) as Array<keyof typeof audience>).map((key, index) => {
            if (audienceFilterKeys.includes(key) && audience[key]) {
                const showDeleteIcon: boolean = !['deviceClass', 'deviceType'].includes(key) || hasBothMandatoryKeys;
                const itemValue = calculateAudienceValues(audience[key]);
                const iconKey = ['deviceClass', 'deviceType', 'operatingSystem'].includes(key) && audience[key];
                items.push(
                    <ItemCard key={`${index}_gridview_item`}>
                        <ItemIcon>
                            <SVGInline src={!!iconKey ? audiencesIcons[key][iconKey as any] : audiencesIcons[key] || icons.valueIcon} />
                        </ItemIcon>
                        <div>
                            <ItemName>{_.truncate(itemValue, { length: 18 })}</ItemName>
                            <ItemType>{_.truncate(splitAudienceKeys(key), { length: 18 })}</ItemType>
                        </div>
                        <ItemActions>
                            <ActionIconHolder>
                                {renderTooltipWithKey(
                                    <SVGInline
                                        src={icons.editLightIcon}
                                        onClick={() => {
                                            if (isLocked) return renderLockedWarningAlert(lockedBy);
                                            setOpenHeaderValuesDialog(true);
                                        }}
                                    />,
                                    'target_groups_audiences_icon_value_edit'
                                )}
                            </ActionIconHolder>
                            <ActionIconHolder>
                                {showDeleteIcon &&
                                    renderTooltipWithKey(
                                        <SVGInline
                                            src={icons.trashLightIcon}
                                            onClick={() => {
                                                if (isLocked) return renderLockedWarningAlert(lockedBy);
                                                if (audienceToEdit) {
                                                    const newAud = { ...audienceToEdit };
                                                    delete newAud[key];
                                                    setAudienceToEdit(newAud);
                                                }
                                            }}
                                        />,
                                        'target_groups_audiences_icon_value_delete'
                                    )}
                            </ActionIconHolder>
                        </ItemActions>
                    </ItemCard>
                );
            }
        });
        items.push(
            <ItemCardAdd
                key={`addcard_item`}
                onClick={() => {
                    if (isLocked) return renderLockedWarningAlert(lockedBy);
                    setOpenHeaderValuesDialog(true);
                }}
            >
                <ItemName>Add Values</ItemName>
                <ItemAddIcon>
                    {renderTooltipWithKey(<SVGInline src={icons.addIcon} />, 'target_groups_audiences_icon_value_add')}
                </ItemAddIcon>
            </ItemCardAdd>
        );
        return <AudiencesGridView>{items}</AudiencesGridView>;
    };

    const toggleArrows = () => {
        setShowSortArrows(!showSortArrows);
    };

    const handleSortIconClick = (field: AcceptedSortField) => {
        setActiveSortingKey(field);
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.field === field && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        const config = {
            field,
            direction
        };
        setSortConfig(config);
        const orderBy = `${config.field}[${config.direction}]`;
        loadAudiences(orderBy, searchTerm);
        setShowSortArrows(false);
    };

    const onPlacedLabelClick = (object: any) => {
        switch (object.type) {
            case ObjectTypes.PAGES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.PAGE.replace(':page_id', object._id)));
                break;
            case ObjectTypes.MODULES:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MODULES), {
                    state: {
                        moduleId: object._id,
                        redirected: true
                    }
                });
                break;
            case ObjectTypes.ITEMS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.ITEMS), {
                    state: { itemId: object._id, openVisualEditor: true }
                });
                break;
            case ObjectTypes.SETTINGS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.SETTINGS), {
                    state: {
                        settingsId: object._id
                    }
                });
                break;
            case ObjectTypes.MENUS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.MENUS), { state: { menuId: object._id } });
                break;
            case ObjectTypes.FEATURE_FLAGS:
                navigate(buildPathWithProjectId(activeProjectId, PageRoutes.FEATURE_FLAGS), { state: { editingId: object._id } });
                break;
            default:
                break;
        }
    };

    const buildTableColumns = () => {
        const columns = [
            <SortableHeaderTableCell
                key={`name_cell`}
                text={'Audience Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.name}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.name)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={AudiencesTableSizes['name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.name && sortConfig?.direction) || 'asc'}
            />,
            <SortableHeaderTableCell
                key={`group_cell`}
                text={'Group Name'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.targetGroupId}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.targetGroupId)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={AudiencesTableSizes['targetGroup.name']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.targetGroupId && sortConfig?.direction) || 'asc'}
            />,
            <HeaderTableCell key={'placed_cell'} text={'Part of Object(s)'} columnSize={AudiencesTableSizes.placed} />,
            <SortableHeaderTableCell
                key={`last_modified_cell`}
                text={'Last Modified'}
                hideArrow={!showSortArrows && activeSortingKey !== ACCEPTED_SORT_FIELDS.lastModified}
                onClick={() => handleSortIconClick(ACCEPTED_SORT_FIELDS.lastModified)}
                onMouseEnter={() => toggleArrows()}
                onMouseLeave={() => toggleArrows()}
                columnSize={AudiencesTableSizes['lastModified']}
                direction={(sortConfig?.field === ACCEPTED_SORT_FIELDS.lastModified && sortConfig?.direction) || 'asc'}
            />
        ];
        return columns;
    };

    const buildTableBody = () => {
        const audiences = [...storeAudiences];

        return (
            <>
                {audiences.map((audience: any, index) => {
                    const isLocked = isObjectLocked(audience);
                    const lockedBy = objectIsLockedBy(audience);
                    const dateString = generateDateStringForTables(audience.lastModified);
                    const expanded = audienceToEdit?._id === audience._id;
                    const isLast = audiences.length - 1 === index;
                    return (
                        <>
                            <ScrollableTableRowWrapper
                                data-cy={`audiences-grid_view-${index}`}
                                shouldScroll={redirectAudienceId === audience._id}
                                key={audience._id}
                            >
                                {/*In case of editing, the input field is rendered*/}
                                {expanded && isEditing ? (
                                    <>
                                        <WidthTableCell {...AudiencesTableSizes.name}>
                                            <AudienceTitleInput>
                                                <DialogTextField
                                                    label={'Audience Name'}
                                                    placeholder={'Audience Name'}
                                                    value={audienceToEdit?.name || ''}
                                                    onChange={(evt: any) => {
                                                        audienceToEdit && setAudienceToEdit({ ...audienceToEdit, name: evt.target.value });
                                                    }}
                                                />
                                            </AudienceTitleInput>
                                        </WidthTableCell>
                                        <WidthTableCell colSpan={3} {...AudiencesTableSizes.select_group}>
                                            <TargetGroupDropdownWrapper>
                                                <DialogDropdownSingle
                                                    options={targetGroupOptions}
                                                    labelText={'Group'}
                                                    placeholder={'Group'}
                                                    onChange={(evt: any) => {
                                                        if (audienceToEdit) {
                                                            const newAud = { ...audienceToEdit };
                                                            newAud.targetGroupId = evt?.value || '';
                                                            setAudienceToEdit(newAud);
                                                        }
                                                    }}
                                                    value={targetGroupOptions.find((tgo) => tgo.value === audienceToEdit?.targetGroupId)}
                                                    clearable
                                                />
                                            </TargetGroupDropdownWrapper>
                                        </WidthTableCell>
                                    </>
                                ) : (
                                    <>
                                        {/* AUDIENCE TITLE TABLE CELL */}
                                        <WidthTableCell {...AudiencesTableSizes.name}>
                                            <AudienceTitle
                                                onClick={() => (showUnsaved ? renderAlertUnsavedChanges() : expandAudience(audience))}
                                            >
                                                <TruncatedText>{audience.name || EMPTY_WORD_STRING}</TruncatedText>
                                                {isLocked && renderLockIcon(lockedBy)}
                                            </AudienceTitle>
                                        </WidthTableCell>

                                        {/* GROUPS TABLE CELL */}
                                        <WidthTableCell {...AudiencesTableSizes['targetGroup.name']}>
                                            <AudienceTargetGroupName>
                                                <Tooltip
                                                    title={audience?.targetGroup?.name || '(Not assigned to any Group)'}
                                                    placement={'top'}
                                                    onClick={() =>
                                                        audience?.targetGroup?._id &&
                                                        navigate(
                                                            buildPathWithProjectId(
                                                                activeProjectId,
                                                                PageRoutes.TARGET_GROUP.replace(':group_id', audience.targetGroup._id)
                                                            )
                                                        )
                                                    }
                                                >
                                                    <ObjectNameLabel>
                                                        <TruncatedText>
                                                            {audience?.targetGroup?.name || '(Not assigned to any Group)'}
                                                        </TruncatedText>
                                                    </ObjectNameLabel>
                                                </Tooltip>
                                            </AudienceTargetGroupName>
                                        </WidthTableCell>

                                        {/* PLACED TABLE CELL */}
                                        <WidthTableCell {...AudiencesTableSizes.placed}>
                                            <Labels
                                                withTranslationTooltip
                                                values={audience.placed}
                                                type={MoreInfoTypes.PLACED}
                                                noOfLabels={isDesktop ? (isLargeDesktop ? 3 : 2) : isMobile ? 0 : 1}
                                                onClickLabel={(object: any) => {
                                                    onPlacedLabelClick(object);
                                                }}
                                            />
                                        </WidthTableCell>

                                        {/* LAST MODIFIED TABLE CELL */}
                                        <WidthTableCell {...AudiencesTableSizes.lastModified}>
                                            <LastModified>
                                                <span>{dateString}</span>
                                                <Tooltip title={audience?.modifiedByUser?.name || ''} placement="right-start">
                                                    <img src={audience?.modifiedByUser?.icon || icons.avatarIcon} alt="" />
                                                </Tooltip>
                                            </LastModified>
                                        </WidthTableCell>
                                    </>
                                )}

                                {/* ACTIONS TABLE CELL */}
                                <WidthTableCell {...AudiencesTableSizes.actions}>
                                    <ObjectActions
                                        actions={[tableActions.PURGE, tableActions.INFO, tableActions.EDIT, tableActions.REMOVE]}
                                        withArrow
                                        open={expanded}
                                        onArrowToggle={() => {
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            expandAudience(audience);
                                        }}
                                        onPurge={() => {
                                            purgeCdnForAudience(audience);
                                        }}
                                        onInfoClick={() => {
                                            setAudienceGuide(audience);
                                        }}
                                        onEdit={() => {
                                            if (
                                                showUnsaved &&
                                                (audience._id !== audienceToEdit?._id ||
                                                    (isEditing && audience._id === audienceToEdit?._id))
                                            )
                                                return renderAlertUnsavedChanges();
                                            if (isLocked) return renderLockedWarningAlert(lockedBy);

                                            setIsEditing(audience._id === audienceToEdit?._id ? !isEditing : true);
                                            audienceToEdit?._id !== audience._id && expandAudience(audience);
                                            unlockOnClose(audience._id || '');
                                            lock(audience._id || '');
                                            setCreatingAudience(false);
                                        }}
                                        onRemove={() => {
                                            if (isLocked) return renderLockedWarningAlert(lockedBy);
                                            if (showUnsaved) return renderAlertUnsavedChanges();
                                            handleDeleteClick(audience._id);
                                        }}
                                        tooltipTexts={{
                                            arrowOpen: 'target_groups_audiences_icon_arrow_show_values',
                                            arrowClose: 'target_groups_audiences_icon_arrow_hide_values',
                                            purge: 'target_groups_audiences_icon_value_purge',
                                            info: 'target_groups_audiences_icon_value_info',
                                            edit: 'target_groups_audiences_icon_edit',
                                            delete: 'target_groups_audiences_icon_remove'
                                        }}
                                    />
                                </WidthTableCell>
                            </ScrollableTableRowWrapper>

                            {/* AUDIENCES TABLE ROW */}
                            {expanded && (
                                <>
                                    {/* SPACE BETWEEN ROWS */}
                                    <TableRow style={{ height: '8px' }} />
                                    <TableRowWrapper>
                                        <WidthTableCell style={{ padding: 0 }} colSpan={5} $um={'px'}>
                                            {view === 'GRID'
                                                ? renderAudienceGridView(audienceToEdit)
                                                : renderAudienceListView(audienceToEdit)}
                                        </WidthTableCell>
                                    </TableRowWrapper>
                                </>
                            )}

                            {/* SPACE BETWEEN ROWS */}
                            {!isLast && <TableRow style={{ height: '24px' }} />}
                        </>
                    );
                })}
                {creatingAudience && renderCreatingAudience()}
            </>
        );
    };

    const renderError = (error: any) => {
        switch (error.code) {
            case API_ERROR_CODES.LOCKED_ERROR:
                return renderLockedError(error);
            default:
                return <BackendErrorDialog error={error} />;
        }
    };

    return (
        <ApplicationWrapper>
            <Sidebar />
            <MainContentWrapper>
                <ScreenTitle
                    loading={loading}
                    title={'Audiences'}
                    withProfile
                    withAddButton={!!storeAudiences.length}
                    addLabel={`Create audience`}
                    onAdd={() => handleAddClick()}
                    circlesSlugOptions={{ default: CIRCLE_SLUGS.audiences, onboarding: ONBOARDING_CIRCLE_SLUGS.audiences }}
                />
                {error && renderError(error)}
                <SearchBarContainer>
                    <SearchBar
                        title={'Search by Name'}
                        disabled={loading}
                        searchTerm={searchTerm}
                        onSearch={handleOnSearch}
                        setSearchTerm={setSearchTerm}
                        tooltipText={'target_groups_audiences_icon_search'}
                    />
                    <ToggleViewSwitch
                        checked={view === 'GRID'}
                        toggleCallback={() => setView(view === 'LIST' ? 'GRID' : 'LIST')}
                        tooltipTexts={{ list: 'target_groups_icon_switch_view_list', grid: 'target_groups_icon_switch_view_grid' }}
                    />
                </SearchBarContainer>

                {loading ? (
                    <Loader title={'Audiences'} />
                ) : !!storeAudiences.length || creatingAudience ? (
                    <GenericTable columns={buildTableColumns()} body={buildTableBody()} />
                ) : null}
                {!loading && !(creatingAudience || audienceToEdit) && (
                    <PageActionsWrapper>
                        <PageActionButton
                            id={AUDIENCE_LIBRARY_ADD_BUTTON_ID}
                            data-cy={'add-resource-button'}
                            onClick={() => {
                                showUnsaved ? renderAlertUnsavedChanges() : handleAddClick();
                            }}
                            label={'Create Audience'}
                            type={'BLUE'}
                        />
                    </PageActionsWrapper>
                )}
            </MainContentWrapper>
            <NewAudience
                open={openHeaderValuesDialog}
                audience={audienceToEdit}
                onSave={(newAudience) => {
                    setAudienceToEdit(newAudience);
                }}
                onClose={() => setOpenHeaderValuesDialog(false)}
            />
            <TargetingGuide
                open={!!audienceGuide}
                audience={audienceGuide}
                onClose={() => {
                    setAudienceGuide(undefined);
                }}
            />
            {(creatingAudience || audienceToEdit) && (
                <PageActions
                    onSave={() => {
                        handleCheckBeforeSave();
                    }}
                    onCancel={() => {
                        showUnsaved ? renderAlertUnsavedChanges() : handleCancelClick();
                    }}
                    disabled={{ save: !showUnsaved }}
                />
            )}
            <AddAudienceToGroupObjectsDialog
                audienceName={audienceToEdit?.name}
                open={showAddAudienceToGroupObjectDialog}
                onClose={() => {
                    setShowAddAudienceToGroupObjectDialog(false);
                    setObjectsWithoutCurrentAudience({});
                }}
                onDontAdd={() => {
                    handleSaveClick();
                }}
                onAdd={(objectIdsToAddAudienceTo: string[]) => {
                    handleSaveClick(objectIdsToAddAudienceTo);
                }}
                objects={objectsWithoutCurrentAudience}
            />
        </ApplicationWrapper>
    );
};

export default AudienceLibrary;
