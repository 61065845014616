import React, { FC } from 'react';
import {
    WidgetHeaderChangeIndicator,
    WidgetHeaderLabelSection,
    WidgetHeaderLayout,
    WidgetHeaderSubtitle,
    WidgetHeaderTitle,
    WidgetHeaderValue,
    WindowHeaderLeftSpacer
} from '../Analytics.css';
import SVGInline from 'react-inlinesvg';
import icons from '../../../style';
import {
    analyticsChangeIconSwitcher,
    AnalyticsChangeIndicator,
    CHART_KEYS_TO_TILE_MAPPING,
    createPercentChangeLabel
} from '../Charts/ChartUtils';
import { renderTooltip, tooltipPositions, tooltipTypes } from '../../common/Tooltips/Tooltips';

const WidgetHeader: FC<{
    title: string;
    subtitle: string;
    comparedTimeframeLabel?: string;
    comparedValueToShow?: string;
    value?: string;
    percentChange?: number;
    changeIndicator?: AnalyticsChangeIndicator;
}> = ({ title, subtitle, comparedTimeframeLabel = '', value, percentChange, changeIndicator, comparedValueToShow }) => {
    const showMinutesAsUM = [
        CHART_KEYS_TO_TILE_MAPPING.average_duration,
        CHART_KEYS_TO_TILE_MAPPING.average_playback_session,
        CHART_KEYS_TO_TILE_MAPPING.average_playback_try,
        CHART_KEYS_TO_TILE_MAPPING.average_session_duration
    ].includes(title as any);
    return (
        <WidgetHeaderLayout>
            <WidgetHeaderLabelSection>
                <WidgetHeaderTitle>{title}</WidgetHeaderTitle>
                <WidgetHeaderSubtitle>{subtitle}</WidgetHeaderSubtitle>
            </WidgetHeaderLabelSection>
            <WindowHeaderLeftSpacer />
            {value && (
                <WidgetHeaderValue>
                    {value}
                    {showMinutesAsUM && <span> minutes</span>}
                </WidgetHeaderValue>
            )}
            {percentChange !== undefined && changeIndicator && (
                <WidgetHeaderChangeIndicator>
                    {renderTooltip(
                        <SVGInline src={analyticsChangeIconSwitcher(changeIndicator)} />,
                        tooltipTypes.TEXT,
                        `${comparedValueToShow}${showMinutesAsUM ? ' minutes' : ''} ${comparedTimeframeLabel}`,
                        tooltipPositions.TOP,
                        true
                    )}
                    <span>{createPercentChangeLabel(percentChange)}</span>
                </WidgetHeaderChangeIndicator>
            )}
        </WidgetHeaderLayout>
    );
};

export default WidgetHeader;
