import styled from 'styled-components';
import getTheme, { fontSizes, fontSizesCSS } from '../../../style/themes/theme';
import { devices, ObjectNameLabel } from '../../../style/styled-components/reusable.css';
import { AudienceListContainer, GridView } from '../../TargetGroups/UXElements/Audiences/Audiences.css';
import { ActionIconHolder, ActionWrapper } from '../../common/Actions/Actions.css';

const theme: any = getTheme();

export const AudienceTitle = styled.div`
    cursor: pointer;
    ${fontSizes.Body2}
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const AudienceTitleInput = styled.div`
    max-width: 100%;
    padding-top: 24px;
`;

export const AudienceTargetGroupName = styled.div`
    width: 100%;
`;

export const TargetGroupDropdownWrapper = styled.div`
    max-width: 320px;
    padding-right: 16px;
    padding-top: 24px;
`;

export const LastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    align-items: center;

    img {
        width: 32px;
        height: 32px;
        margin-left: auto;
        object-position: center;
        object-fit: cover;
        border-radius: 50%;

        ${devices.desktopS} {
            display: none;
        }
    }
`;

export const ListContainer = styled(AudienceListContainer)`
    padding: 0 0 0 24px;
    border-top: 1px solid ${theme.palette.background.pressed};

    &:hover {
        ${ActionWrapper} {
            ${ActionIconHolder} {
                display: inline;
                &:last-child {
                    display: none;
                }
            }
        }
    }
`;

export const AudiencesGridView = styled(GridView)`
    margin-bottom: 0;
`;
