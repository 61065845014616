import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import config from '../../../config/cc-config.json';
import icons from '../../../style';
import { IconPreviewImage, SidebarSVGInline, StyledSVGInline } from '../../../style/styled-components/reusable.css';
import {
    AppLogo,
    AppLogoCollapsed,
    AppWrapper,
    CustomDrawer,
    EngageDropdownItemRow,
    EngageDropdownItemTitle,
    EngageMenuItemsDialog,
    EngageSelectArrow,
    EngageSidebarSVGInline,
    EngageTitle,
    EngageWrapper,
    GroupProjectTitle,
    GroupStyled,
    GroupTitle,
    ItemRow,
    ItemRowsWrapper,
    ProjectMenuItem,
    ProjectMenuItemImg,
    ProjectSelectArrow,
    ProjectSelectDropdownMenu,
    SideBarArrow,
    SidebarFooter,
    SidebarItemActions,
    SidebarWrapper
} from './Sidebar.css';
import _ from 'lodash';
import { useAppDispatch as useDispatch, useAppSelector } from '../../../hooks/redux';
import {
    calculateIsProjectLead,
    calculateIsTenantAdmin,
    PermissionsState,
    setUserPermissions
} from '../../../redux/slices/permissionsSlice';
import {
    closeSideBar,
    setGroupKeyToExpandOnSidebarReopen,
    toggleEngageSidebar,
    toggleSidebar
} from '../../../redux/slices/sidebarOpenSlice';
import { Tooltip } from '@material-ui/core';
import { fetchProjects, ProjectsState, updateProject as editProject } from '../../../redux/slices/projectsSlice';
import BackendErrorDialog from '../Dialog/BackendErrorDialog';
import { Project } from '../../../types/Project';
import AddLinkDialog, { ExtraLink } from './AddPage/AddLinkDialog';
import SVGInline from 'react-inlinesvg';
import { DIALOG_NAMES, dialogConfirm } from '../../../utils/fnDialogs';
import { renderTooltip, renderTooltipWithKey, tooltipTypes } from '../Tooltips/Tooltips';
import useScreenSize from '../../../hooks/useScreenSize';
import { buildPathWithProjectId, changeProjectIdPath, PageRoutes } from '../../../types/RouteTypes';
import { docsWidget } from '../../App';
import { TENANTS_WITH_ASSET_MANAGER } from '../../AssetManager/AssetManager';

const { more, experience, sources, targeting, capabilities } = config;

type SidebarProps = {
    onProjectSelect?: any;
    isUserPage?: boolean;
    callback?: any;
    activePage?: string;
    disabled?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ onProjectSelect, isUserPage, callback, activePage, disabled }) => {
    const { sidebarOpen, engageSidebarOpen, groupKeyToExpandOnSidebarReopen } = useAppSelector((state) => state.sidebarOpen);
    const [projectDropdownOpen, setProjectDropdownOpen] = useState<boolean>(false);
    const [openAddPageDialog, setOpenAddPageDialog] = useState<boolean>(false);
    const [editingExtraPage, setEditingExtraPage] = useState<ExtraLink | undefined>(undefined);
    const [hoverPosition, setHoverPosition] = useState<{ top: number; left: number } | null>(null);
    const [hoverIndex, setHoverIndex] = useState<any>(null);
    const [skipProjectEffect, setSkipProjectEffect] = useState(true);
    const { userPermissions }: PermissionsState = useAppSelector((state) => state.permissions);
    const { projects, error, loading }: ProjectsState = useAppSelector((state) => state.projects);
    const { activeProjectId, activeTenantId } = useAppSelector((state) => state.activeItem);
    const { loading: pagesLoading } = useAppSelector((state) => state.pages);
    const { loading: modulesLoading } = useAppSelector((state) => state.modules);
    const { loading: itemsLoading } = useAppSelector((state) => state.items);
    const { loading: menusLoading } = useAppSelector((state) => state.menus);
    const { loading: settingsLoading } = useAppSelector((state) => state.settings);
    const { loading: pageStylesLoading } = useAppSelector((state) => state.pageStyles);
    const { loading: sourcesLoading } = useAppSelector((state) => state.dynamicSources);
    const { loading: languagesLoading } = useAppSelector((state) => state.languages);
    const { loading: audiencesLoading } = useAppSelector((state) => state.audiences);
    const { loading: displayConditionsLoading } = useAppSelector((state) => state.displayConditions);
    const { loading: targetGroupsLoading } = useAppSelector((state) => state.targetGroups);
    const { loading: unpublishedLoading } = useAppSelector((state) => state.unpublishedChanges);

    const sidebarRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { isDesktop } = useScreenSize();
    const leaveTimerRef = useRef<NodeJS.Timeout | null>(null);

    const canAddExtraPages = useMemo(
        () =>
            userPermissions?.isSuperAdmin ||
            calculateIsTenantAdmin(activeTenantId, userPermissions) ||
            calculateIsProjectLead(activeProjectId, userPermissions),
        [userPermissions, activeTenantId, activeProjectId]
    );

    const resourcesLoading =
        pagesLoading ||
        modulesLoading ||
        itemsLoading ||
        menusLoading ||
        settingsLoading ||
        pageStylesLoading ||
        sourcesLoading ||
        languagesLoading ||
        audiencesLoading ||
        displayConditionsLoading ||
        targetGroupsLoading ||
        unpublishedLoading ||
        loading;
    const toggleSidebarOpen = () => {
        if (sidebarOpen) {
            // we are closing the sidebar
            toggleEngageSidebarOpen('');
            saveGroupKeyToExpandOnSidebarReopen(engageSidebarOpen);
        } else {
            toggleEngageSidebarOpen(groupKeyToExpandOnSidebarReopen);
            saveGroupKeyToExpandOnSidebarReopen('');
        }

        dispatch(toggleSidebar());
    };

    const toggleEngageSidebarOpen = (expandedGroup: string) => {
        dispatch(toggleEngageSidebar({ expandedGroup }));
    };

    const saveGroupKeyToExpandOnSidebarReopen = (groupKeyToSave: string) => {
        dispatch(setGroupKeyToExpandOnSidebarReopen({ groupKeyToSave }));
    };
    const handleClose = () => {
        dispatch(toggleEngageSidebar({ expandedGroup: '' }));
        dispatch(closeSideBar());
    };

    useEffect(() => {
        loadProjects(true).then((response) => {
            if (response.permissions) {
                dispatch(setUserPermissions(response.permissions));
            }
        });
    }, []);

    useEffect(() => {
        const clickOutside = (evt: any) => {
            // Prevents unwanted collapsing of the menu on Desktop.
            if (evt.target.isSameNode(docsWidget?.element)) return;

            if (sidebarRef?.current && !sidebarRef.current.contains(evt.target)) {
                handleClose();
            }
        };
        if (!sidebarOpen || isDesktop) return;

        document.addEventListener('click', clickOutside);
        document.addEventListener('touchstart', clickOutside);
        return () => {
            document.removeEventListener('click', clickOutside);
            document.removeEventListener('touchstart', clickOutside);
        };
    }, [sidebarOpen, isDesktop]);

    useEffect(() => {
        if (isUserPage) return;
        // the effect must not run the first time - it will automatically cause a redirect
        if (skipProjectEffect) setSkipProjectEffect(false);
        else {
            if (!projects.map((p) => p._id).includes(activeProjectId)) {
                navigate(PageRoutes.PROJECTS);
            }
        }
    }, [projects, activeProjectId]);

    const loadProjects = async (addPermissions?: boolean) => {
        return await dispatch(fetchProjects({ addPermissions })).unwrap();
    };

    const updateProject = async (project: Project) => {
        if (project.logo) {
            project.logo = encodeURIComponent(project.logo);
        }
        await dispatch(editProject(project)).unwrap();
        loadProjects();
    };

    const deleteExtraPage = (id: string) => {
        if (!activeProjectId) return;
        const project = projects.find((project) => project._id === activeProjectId);
        if (!project || !project.extraPages) return;
        const newExtraPages = project?.extraPages && [...project?.extraPages];
        const pageIndex = project?.extraPages?.findIndex((page) => page.id === id);
        if (pageIndex === undefined || pageIndex < 0) return;
        newExtraPages?.splice(pageIndex, 1);
        const newProject = { ...project, extraPages: newExtraPages };
        updateProject(newProject);
    };

    const setCurrentProject = (project: Project) => {
        const { _id } = project;
        navigate(changeProjectIdPath(location.pathname, _id));
        onProjectSelect?.(_id);
        setProjectDropdownOpen(!projectDropdownOpen);
    };

    const renderAddOnComponents = () => {
        let items: JSX.Element[] = [];
        capabilities.length &&
            capabilities.forEach((item: any, index) => {
                const { key, title, icon, component_key, external_link } = item;
                const itemIcon = _.get(icons, `${icon}`, '');

                items.push(
                    <ItemRow
                        key={`ux_item_${index}`}
                        $active={location.pathname === buildPathWithProjectId(activeProjectId, `/${component_key || key}`)}
                        onClick={() => {
                            if (!external_link) {
                                navigate(buildPathWithProjectId(activeProjectId, `/${component_key || key}`));
                            } else {
                                window.open(external_link, '_blank', 'noopener,noreferrer');
                            }
                        }}
                    >
                        <a>
                            {sidebarOpen ? (
                                <SidebarSVGInline src={itemIcon} />
                            ) : (
                                renderTooltip(<SidebarSVGInline src={itemIcon} />, tooltipTypes.TEXT, title)
                            )}
                            {sidebarOpen && <div className="title">{title}</div>}
                        </a>
                    </ItemRow>
                );
            });
        return items;
    };

    const renderMoreComponents = () => {
        let items: JSX.Element[] = [];
        more.length &&
            more.forEach((item, index) => {
                const itemIcon = _.get(icons, `${item.icon}`, '');
                if (canAddExtraPages && item.key === 'add_link') {
                    items.push(
                        <ItemRow
                            key={`more_item_${index}`}
                            $active={location.pathname === `/${item.key}`}
                            onClick={() => setOpenAddPageDialog(true)}
                        >
                            <a>
                                {sidebarOpen ? (
                                    <SidebarSVGInline src={itemIcon} />
                                ) : (
                                    renderTooltip(<SidebarSVGInline src={itemIcon} />, tooltipTypes.TEXT, item.title)
                                )}
                                {sidebarOpen && <div className="title">{item.title}</div>}
                            </a>
                        </ItemRow>
                    );
                }
            });

        return items;
    };

    const renderExtraPages = () => {
        let items: JSX.Element[] = [];

        more.length &&
            more.forEach((item) => {
                const itemIcon = _.get(icons, `${item.icon}`, '');
                if (item.key === '3ready+_link') {
                    items.push(
                        <ItemRow
                            key={`more_item_${item.key}`}
                            $active={location.pathname === `/${item.key}`}
                            onClick={() => window.open(item.url, '_blank', 'noreferrer')}
                        >
                            <a>
                                {sidebarOpen ? (
                                    <SidebarSVGInline src={itemIcon} />
                                ) : (
                                    renderTooltip(<SidebarSVGInline src={itemIcon} />, tooltipTypes.TEXT, item.title)
                                )}
                                {sidebarOpen && <div className="title">{item.title}</div>}
                            </a>
                        </ItemRow>
                    );
                }
            });
        const project = projects.find((p) => p._id === activeProjectId);
        project?.extraPages?.length &&
            project?.extraPages.forEach((item: ExtraLink, index) => {
                const itemIcon = _.get(icons, `${item.icon}`, '');
                items.push(
                    <ItemRow
                        $extraPages
                        key={`extra_page_${index}`}
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                        onClick={() => window.open(item.url, '_blank', 'noreferrer')}
                    >
                        <a>
                            {!(item.icon in icons) ? (
                                <IconPreviewImage src={item.icon} $invert={item.icon.includes('.svg')} />
                            ) : (
                                <SidebarSVGInline src={itemIcon} />
                            )}
                            {sidebarOpen && <div className="title">{_.truncate(item.name, { length: 15 })}</div>}
                        </a>
                        {canAddExtraPages && sidebarOpen && hoverIndex === index && (
                            <SidebarItemActions>
                                {renderTooltipWithKey(
                                    <SVGInline
                                        src={icons.editIcon}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setEditingExtraPage(item);
                                            setOpenAddPageDialog(true);
                                        }}
                                    />,
                                    'sidebar_item_icon_edit'
                                )}
                                {renderTooltipWithKey(
                                    <SVGInline
                                        src={icons.trashIcon}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            dialogConfirm(DIALOG_NAMES.GENERIC_DELETE_CONFIRM, () => {
                                                deleteExtraPage(item.id);
                                            });
                                        }}
                                    />,
                                    'sidebar_item_icon_delete'
                                )}
                            </SidebarItemActions>
                        )}
                    </ItemRow>
                );
            });
        return items;
    };

    const renderUserManagement = () => {
        const elements = [
            { title: 'Users', icon: 'usersIcon' },
            { title: 'User-Groups', icon: 'usersAndGroupsIcon' }
        ];
        let items: JSX.Element[] = [];

        elements.forEach((item, index) => {
            const itemIcon = _.get(icons, `${item.icon}`, '');
            items.push(
                <ItemRow
                    key={index}
                    $active={activePage === item.title}
                    onClick={() => {
                        callback && callback(item.title);
                    }}
                >
                    <a>
                        {sidebarOpen ? (
                            <SidebarSVGInline src={itemIcon} />
                        ) : (
                            renderTooltip(<SidebarSVGInline src={itemIcon} />, tooltipTypes.TEXT, item.title)
                        )}
                        {sidebarOpen && <div className="title">{item.title}</div>}
                    </a>
                </ItemRow>
            );
        });

        return items;
    };

    if (isUserPage) {
        return (
            <>
                {error && <BackendErrorDialog error={error} />}
                <CustomDrawer ref={sidebarRef} variant="permanent" open={sidebarOpen}>
                    <SideBarArrow onClick={toggleSidebarOpen} open={sidebarOpen}>
                        <Tooltip title={sidebarOpen ? 'Collapse' : 'Expand'} placement="right">
                            <div>
                                <StyledSVGInline src={icons.sidebarCollapse} />
                            </div>
                        </Tooltip>
                    </SideBarArrow>
                    <SidebarWrapper>
                        <AppWrapper>
                            {sidebarOpen ? (
                                <AppLogo src={icons.logo3Ready} onClick={() => navigate('/')} />
                            ) : (
                                <AppLogoCollapsed src={icons.logo3ReadyShort} onClick={() => navigate('/')} />
                            )}
                        </AppWrapper>
                        <GroupStyled>
                            {sidebarOpen && <GroupTitle>User Management</GroupTitle>}
                            <ItemRowsWrapper>{renderUserManagement()}</ItemRowsWrapper>
                        </GroupStyled>
                    </SidebarWrapper>
                </CustomDrawer>
            </>
        );
    }

    const filterActiveProject = (projects: Project[]) => {
        return projects.filter((project) => {
            return project._id === activeProjectId;
        });
    };

    const renderSelectedProject = () => {
        const [selectedProject] = filterActiveProject(projects);
        const numOfProjectsInActiveTenant = projects.reduce((sum, project) => (project.tenantId === activeTenantId ? ++sum : sum), 0);
        return (
            <Tooltip title={selectedProject?.name} placement={'top'}>
                <ProjectMenuItem sidebarOpen={sidebarOpen} onClick={() => setProjectDropdownOpen(!projectDropdownOpen)} $isActive>
                    <ProjectMenuItemImg src={selectedProject?.logo || icons.projectIcon1} />
                    {sidebarOpen && <div>{_.truncate(selectedProject?.name, { length: 18 })}</div>}
                    {sidebarOpen && numOfProjectsInActiveTenant > 1 && (
                        <ProjectSelectArrow src={projectDropdownOpen ? icons.arrowUpIcon : icons.arrowDownIcon} />
                    )}
                </ProjectMenuItem>
            </Tooltip>
        );
    };

    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, group: string, isDialog: boolean) => {
        if (!sidebarOpen) {
            if (!isDialog) {
                const rect = event.currentTarget.getBoundingClientRect();
                setHoverPosition({ top: rect.top, left: rect.right });
            }
            toggleEngageSidebarOpen(group);
            if (leaveTimerRef.current) {
                clearTimeout(leaveTimerRef.current);
                leaveTimerRef.current = null;
            }
        }
    };

    const handleMouseLeave = () => {
        if (!sidebarOpen) {
            leaveTimerRef.current = setTimeout(() => {
                toggleEngageSidebarOpen('');
            }, 250);
        }
    };

    const menuItems = (items: any[], groupKey: string) =>
        items.map((item, index) => {
            if (item.key === 'asset_manager' && !TENANTS_WITH_ASSET_MANAGER.includes(activeTenantId)) {
                return null;
            }

            const itemIcon = _.get(icons, `${item.icon}`, '');
            return (
                <EngageDropdownItemRow
                    key={`engage_${groupKey}_${index}`}
                    data-cy={`sidebar-experience-item-${item.key}`}
                    $active={location.pathname.includes(buildPathWithProjectId(activeProjectId, `/${item.key}`))}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleEngageSidebarOpen(sidebarOpen ? groupKey : '');
                        saveGroupKeyToExpandOnSidebarReopen(groupKey);

                        navigate(buildPathWithProjectId(activeProjectId, `/${item.key}`));
                    }}
                >
                    <a>
                        {renderTooltip(<SidebarSVGInline src={itemIcon} />, tooltipTypes.TEXT, item.title)}
                        <div className="title">{item.title}</div>
                    </a>
                </EngageDropdownItemRow>
            );
        });

    const renderMenu = (groupKey: string, groupTitle: string, groupIcon: string, items: any[]) => {
        const isGroupActive = items.some((item) => location.pathname.includes(buildPathWithProjectId(activeProjectId, `/${item.key}`)));

        return (
            <ProjectSelectDropdownMenu
                key={`engage_${groupKey}_${sidebarOpen ? engageSidebarOpen : groupTitle}}`}
                triggerButton={
                    <EngageDropdownItemRow
                        onMouseEnter={(event) => handleMouseEnter(event, groupKey, false)}
                        onMouseLeave={handleMouseLeave}
                        onClick={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            toggleEngageSidebarOpen(engageSidebarOpen !== groupKey ? groupKey : '');
                        }}
                        $active={isGroupActive}
                        data-cy={`sidebar-engage-header-${groupKey}`}
                    >
                        {sidebarOpen ? (
                            <EngageSidebarSVGInline src={groupIcon} />
                        ) : (
                            renderTooltip(<EngageSidebarSVGInline src={groupIcon} />, tooltipTypes.TEXT, groupTitle)
                        )}

                        {sidebarOpen && <EngageDropdownItemTitle>{groupTitle}</EngageDropdownItemTitle>}
                        {sidebarOpen && (
                            <EngageSelectArrow src={engageSidebarOpen === groupKey ? icons.arrowUpIcon : icons.arrowDownIcon} />
                        )}
                    </EngageDropdownItemRow>
                }
                showMenuContent={engageSidebarOpen === groupKey}
                horizontalPosition={'right'}
                closeOnMouseLeave={false}
                verticalPosition={'top'}
                modified
            >
                {sidebarOpen && <div>{menuItems(items, groupKey)}</div>}
            </ProjectSelectDropdownMenu>
        );
    };

    if (!loading && !userPermissions) {
        // if data is loaded and permissions are not existant, display an error
        return <BackendErrorDialog error={{ status: 401 }} />;
    }

    return (
        <>
            {error && <BackendErrorDialog error={error} />}
            <CustomDrawer ref={sidebarRef} variant="permanent" open={sidebarOpen} $disabled={disabled}>
                <SideBarArrow onClick={toggleSidebarOpen} open={sidebarOpen}>
                    <Tooltip title={sidebarOpen ? 'Collapse' : 'Expand'} placement="right">
                        <div>
                            <StyledSVGInline src={icons.sidebarCollapse} />
                        </div>
                    </Tooltip>
                </SideBarArrow>
                <SidebarWrapper>
                    <AppWrapper>
                        {sidebarOpen ? (
                            <AppLogo src={icons.logo3Ready} onClick={() => navigate('/')} />
                        ) : (
                            <AppLogoCollapsed src={icons.logo3ReadyShort} onClick={() => navigate('/')} />
                        )}
                    </AppWrapper>
                    <GroupProjectTitle>
                        <ProjectSelectDropdownMenu
                            closeOnMouseLeave={false}
                            triggerButton={renderSelectedProject()}
                            horizontalPosition={'right'}
                            verticalPosition={'top'}
                            modified
                            optionsDisabled={resourcesLoading}
                        >
                            {projects
                                ?.filter((p) => p.tenantId === activeTenantId)
                                .map((project, index) => {
                                    if (project._id === activeProjectId) return null;
                                    return (
                                        <Tooltip title={project.name} key={index} placement={'top'}>
                                            <ProjectMenuItem key={index} onClick={() => setCurrentProject(project)}>
                                                <ProjectMenuItemImg src={project.logo || icons.projectIcon1} />
                                                {sidebarOpen && <div>{_.truncate(project.name, { length: 20 })}</div>}
                                            </ProjectMenuItem>
                                        </Tooltip>
                                    );
                                })}
                        </ProjectSelectDropdownMenu>
                    </GroupProjectTitle>
                    <EngageWrapper>
                        {sidebarOpen && <EngageTitle>Engage</EngageTitle>}
                        {renderMenu('experience', 'Experience', icons.experienceIcon, experience)}
                        {renderMenu('sources', 'Sources', icons.sourcesIcon, sources)}
                        {renderMenu('targeting', 'Targeting', icons.targetsIcon, targeting)}
                    </EngageWrapper>

                    <GroupStyled>
                        {sidebarOpen && <GroupTitle> Capabilities </GroupTitle>}
                        <ItemRowsWrapper>{renderAddOnComponents()}</ItemRowsWrapper>
                    </GroupStyled>
                    <GroupStyled>
                        {sidebarOpen && <GroupTitle>Links</GroupTitle>}
                        <ItemRowsWrapper>{renderExtraPages()}</ItemRowsWrapper>
                        <ItemRowsWrapper>{renderMoreComponents()}</ItemRowsWrapper>
                    </GroupStyled>
                    <AddLinkDialog
                        open={openAddPageDialog}
                        onClose={() => {
                            setEditingExtraPage(undefined);
                            setOpenAddPageDialog(false);
                        }}
                        onSave={(newPage: ExtraLink) => {
                            const project = projects.find((project) => project._id === activeProjectId);
                            if (!project) return;
                            const newExtraPages: ExtraLink[] = editingExtraPage
                                ? [...(project.extraPages || [])]
                                : [...(project.extraPages || []), newPage];
                            if (editingExtraPage) {
                                const index = newExtraPages.findIndex((ep: ExtraLink) => ep.id === newPage.id);
                                newExtraPages.splice(index, 1, newPage);
                            }
                            const newProject = { ...project, extraPages: newExtraPages };
                            updateProject(newProject);
                            setEditingExtraPage(undefined);

                            setOpenAddPageDialog(false);
                        }}
                        extraLink={editingExtraPage}
                    />
                    <SidebarFooter>{sidebarOpen ? `3READY CC 3.0 - version ${process.env.REACT_APP_VERSION}` : 'CC 3.0'}</SidebarFooter>
                </SidebarWrapper>
            </CustomDrawer>
            {engageSidebarOpen.length > 0 && !sidebarOpen && hoverPosition && (
                <EngageMenuItemsDialog
                    style={{ top: hoverPosition.top, left: hoverPosition.left }}
                    onMouseEnter={(event) => handleMouseEnter(event, engageSidebarOpen, true)}
                    onMouseLeave={handleMouseLeave}
                >
                    {menuItems(
                        engageSidebarOpen === 'experience' ? experience : engageSidebarOpen === 'sources' ? sources : targeting,
                        engageSidebarOpen
                    )}
                </EngageMenuItemsDialog>
            )}
        </>
    );
};

export default Sidebar;
